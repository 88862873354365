@white: #fff;
@black: #000;
@ghost-white: #e7e7ef;
//adding code to make it compile

@primary-color: #2f95e3; // primary color for all components
@link-color: @primary-cta; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #c62727; // error state color
@cta-color: #e60b6f;
@dark-cta-color: #cc8617;
@green-slate-grey: #798589;
@light-gray: rgb(242, 245, 244);
@dark-gray: #5a7887;
@gray-medium: #647784;
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-color-base: #787878; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
@box-shadow-light: 1px 5px 15px 0 rgba(0, 0, 0, 0.05); //shadow for cart
@drop-shadow-light: 1px 5px 15px rgba(0, 0, 0, 0.05); //shadow for cart
@info-text-color: #46535e;
@multiple-card-title: #4a5664;
@multiple-card-address: #798589;
@search-result-highlight: #fce8a9;
@cinderella: #fde2df;

// Background color for `<body>`
@body-background: #fff;
// Base background color for most components
@component-background: #fff;

// Background color for `<SubscribeBanner>`
// TC update @subscribe-banner-background: #0f8564;
@subscribe-banner-background: #13b34e;

@learn-landing-hero-background: linear-gradient(
  -225deg,
  #86a5ab 0%,
  #b4cacd 100%
);
@email-signup-banner-background: linear-gradient(
  45deg,
  #86a5ab 0%,
  #b4cacd 100%
);

@font-family: @font-sans-roman;
@code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
  monospace;
@font-sans-roman: 'Avenir-Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-sans-book: 'Avenir-Book', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-sans-black: 'Avenir-Black', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-sans-medium: 'Avenir-Medium', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
@font-sans-bold: 'Avenir-Heavy', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-sans-oblique: 'Avenir-BookOblique', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
@font-serif-medium: 'Aesthet-Nova-Medium', Georgia, Times, 'Times New Roman',
  serif;
@font-serif-semibold: 'Aesthet-Nova-Medium', Georgia, Times, 'Times New Roman',
  serif;
@font-serif-bold: 'Aesthet-Nova-Medium', Georgia, Times, 'Times New Roman',
  serif;
@font-serif-text-regular: 'Aesthet-Nova-Regular', Georgia, Times,
  'Times New Roman', serif;
@font-serif-text-italic: 'Aesthet-Nova-Regular', Georgia, Times,
  'Times New Roman', serif;
@text-color: @I90;
@text-color-secondary: @I70;
@icon-color: inherit;
@icon-color-hover: fade(@black, 75%);
@heading-color: @indigo;
@heading-color-dark: fade(@white, 100%);
@text-color-dark: fade(@white, 85%);
@text-color-secondary-dark: fade(@white, 65%);
@font-variant-base: tabular-nums;
@font-feature-settings-base: 'tnum';
@line-height-base: 1.5;
@border-radius-base: 0px; // major border radius
@border-radius-sm: 2px;
@border-radius-md: 4px;
@border-radius-none: 0px;

// Tag
// --
@tag-default-color: @I90;
@tag-font-size: @text-2-size;
@tag-line-height: @display-subhead2-size;

// vertical paddings
@mds-padding-lg: 120px; // between section
@mds-padding-md: 44px; // between header and content
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items

// Border color
@border-color-base: @I50; // base border outline a component
@border-color-split: hsv(0, 0, 91%); // split border inside a component
@border-color-inverse: @white;
@border-width-base: 1px; // width of the border for a component
@border-width-sm: 2px; // width of the border for a component
@border-width-md: 3px; // width of the border for a component
@border-style-base: solid; // style of a components border
@table-border-color: #f0f0f0; // default antd border color

// Outline
@outline-blur-size: 0;
@outline-width: 2px;
@outline-color: @primary-main-color;

@background-color-light: hsv(
  0,
  0,
  98%
); // background of header and selected item
@background-color-base: hsv(0, 0, 96%); // Default grey background color

// Disabled states
@disabled-color: @I50;
@disabled-bg: @salt;
@disabled-color-dark: fade(#fff, 35%);

// Navbar
@nav-height: 80px;

// Form
@label-color: @I70;
@form-item-margin-bottom: 10px;
@form-item-label-font-size: @font-size-base;
@form-item-vertical-label-line-height: 22px;
@form-item-error-message-line-height: 22px;
@form-error-input-bg: @light-red;

// Inputs
@input-height-base: 40px;
@input-hover-border-color: @primary-main-color;
@input-border-color: @border-color-base;
@input-placeholder-color: @I50;
@input-disabled-bg: @I10;
@input-disabled-color: @I50;
@input-padding-horizontal: @padding-md;
@input-padding-vertical-base: 10px;

// Select
@select-border-color: @I50;

// Tabs
@tabs-horizontal-margin: 0;

//Typography
@font-size-base: 16px;

@heading-1-size: ceil(@font-size-base * 3);
@heading-2-size: ceil(@font-size-base * 2.25);
@heading-3-size: ceil(@font-size-base * 2.14);
@heading-4-size: ceil(@font-size-base * 1.71);

// MDsave display fonts
@display-alpha-size: 56px;
@display-beta-size: 40px;
@display-beta-mobile-size: 30px;
@display-num1-size: 200px;
@display-num2-size: 48px;
@display-num3-size: 22px;

//MDsave normal sizes
@display-h1-size: 30px;
@display-h2-size: 24px;
@display-name-size: 22px;
@display-subhead1-size: 22px;
@display-subhead2-size: 18px;
@display-subhead3-size: 16px;

//Ant & MDsave body sizes
@text-080-size: 8px;
@text-0-size: 10px;
@text-1-size: 12px;
@text-2-size: 14px;
@text-3-size: 15px;
@text-4-size: 16px;
@text-5-size: 18px;
@text-6-size: 20px;
@text-7-size: 25px;
@text-8-size: 30px;
@text-9-size: 40px;
@text-10-size: 60px;

@font-weight-xxbold: 900;
@font-weight-xbold: 700;
@font-weight-bold: 500;
@font-weight-normal: 400;

// Buttons
@btn-font-weight: 400;
@btn-border-radius-base: @border-radius-md;
@btn-border-radius-sm: @border-radius-md;
@btn-shadow: none;
@btn-shadow-inset: inset 0 0 0;
@btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
@btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@btn-default-color: @text-color;
@btn-default-bg: #fff;
@btn-default-border: @border-color-base;

@btn-danger-color: @error-color;
@btn-danger-bg: @background-color-base;
@btn-danger-border: @border-color-base;

@btn-disable-color: @disabled-color;
@btn-disable-bg: @disabled-bg;
@btn-disable-border: @border-color-base;

@btn-padding-base: 0 35px;
@btn-font-size-lg: @text-4-size;
@btn-font-size-sm: @font-size-base;
@btn-padding-lg: @btn-padding-base;
@btn-padding-sm: 0 @padding-xs - 1px;

@btn-height-base: 50px;
@btn-height-lg: 58px;
@btn-height-sm: 42px;

@btn-circle-size: @btn-height-base;
@btn-circle-size-lg: @btn-height-lg;
@btn-circle-size-sm: @btn-height-sm;

@btn-outer-border-size: 2px;
@btn-inner-border-size: 4px;

// Carousel
@carousel-primary-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.1);
@carousel-quote-color: #f3c13f;
@carousel-dots-color: @I30;
@carousel-dots-active-color: @white;

// TC update @primary-main-color: #3178a4;
@primary-main-color: #0471ec;
// TC update @primary-hover-color: #296285;
@primary-hover-color: #0471ec;
@primary-active-color: #223d50;
@primary-focus-color: @primary-hover-color;
@primary-disabled-border: @I10;
@primary-disabled-background: @primary-disabled-border;
@primary-disabled-color: @I50;
@primary-visited-color: #7428aa;

// TC update @secondary-main-color: #f7c629;
@secondary-main-color: #e60b6f;
// TC update @secondary-hover-color: #f6bf10;
@secondary-hover-color: #e60b6f;
// TC update @secondary-active-color: #e4b009;
@secondary-active-color: #e60b6f;
@secondary-focus-color: @secondary-main-color;
@secondary-disabled-border: @primary-disabled-border;
@secondary-disabled-background: @primary-disabled-background;
@secondary-disabled-color: @primary-disabled-color;

@tertiary-main-color: @primary-main-color;
@tertiary-hover-color: @primary-main-color;
@tertiary-active-color: @primary-hover-color;
@tertiary-focus-color: @primary-main-color;
@tertiary-disabled-border: @primary-disabled-border;
// TC update @tertiary-disabled-background: #f8f8f5;
@tertiary-disabled-background: #f8f9fb;
@tertiary-disabled-color: @primary-disabled-color;

@white-tertiary-main-color: @white;
@white-tertiary-hover-color: @primary-main-color;
@white-tertiary-hover-bg: @white;
@white-tertiary-active-bg: @beige;
@white-tertiary-focus-color: @primary-main-color;
@white-tertiary-disabled-border: @primary-disabled-border;
@white-tertiary-disabled-background: rgba(255, 255, 255, 0.2);
@white-tertiary-disabled-color: rgba(255, 255, 255, 0.3);

//gradient Colors
@light-blue-start-1: #3db3f3;
@dark-blue-end-1: #1aa7ef;
@light-blue-start-2: rgb(19, 81, 118);
@dark-blue-end-2: rgba(47, 149, 227, 1);
@light-blue-start-3: #86a5ab;
@dark-blue-end-3: #b4cacd;
// TC update @blue-dark-medium: #3178a4;
@blue-dark-medium: #0471ec;
@dark-blue-end-4: #325063;
@dark-wheat: rgba(255, 255, 255, 0.6);

//shadows
@cart-modal-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
  0 1px 3px 0 rgba(63, 63, 68, 0.15);

//colors
// TC update @astral: #3178a4;
@astral: #0471ec;
@red-xlight: rgba(198, 39, 39, 0.05);
@red-xlight-10: rgba(198, 39, 39, 0.1);
@gray-light: #f2f5f4;
@green-xlight: #dee5e1;
@blue-fountain: #57c1c1;
@blue-dianne: #223d50;
@blue-bayoux: #4e6373;
@porcelain: #f3f5f6;
@gray-light-10: rgba(0, 0, 0, 0.1);
@deep-blush: #ea808c;
@illusion: #f89dc5;
@burnt-sienna: #eb5e42;
@raw-sienna: #d66d50;
@canary: rgba(255, 255, 255, 0.6);
@japonica: #de767c;
@your-pink: #ffbcb9;
@mystic-grey: #dbe3df;
@blue-clay: #699094;
@london-rain-light: rgba(1, 92, 185, 0.13);
@mandy: #c7515f;
@dark-coral: #cd533b;
@dusty-blue: #518fb0;
@merino: #f8edea;
@illusion: #f4a0c4;
@raspberry: #ed0b6f;
@cyan-blue: #ebf1f5;

//Mdsave Color Palette
//Primary
// TC update @mdsave-blue: #296285;
@mdsave-blue: #0471ec;
// TC update @mdsave-blue-logo: rgb(42, 98, 133);
@mdsave-blue-logo: rgb(4, 113, 236);
// TC update @primary-cta: #3178a4;
@primary-cta: #0471ec;
@B100: @primary-cta;
// TC update@B70: #6ea0bf;
@B70: #68aaf4;
// TC udpate @B50: #98bbd1;
@B50: #9bc6f7;
// TC update @B20: #d6e4ed;
@B20: #cde3fb;
// TC update @B10: #eaf1f5;
@B10: #e6f1fe;

@B101: #ecf5ff; // New color
// TC update @B05: #f5f9fb;
@B05: #f2f8fe;

//Fonts & Grays
// TC update @indigo: #223d50;
@indigo: #40403e;
// TC update @I90: #385062;
@I90: #3a3a3a;
// TC update @I80: #4e6473;
@I80: #4e6473;
// TC update @I70: #647785;
@I70: #555554;
// TC update @I60: #7a8b96;
@I60: #636362;
// TC update @I50: #909ea7;
@I50: #767674;
// TC update @I40: #a7b1b9;
@I40: #767674;
// TC update @I30: #bcc4ca;
@I30: #767674;
// TC update @I20: #d3d8dc;
@I20: #d9d9d9;
// TC update @I10: #e8ebed;
@I10: #ececec;
// TC update @I05: #f3f5f6;
@I05: #f5f5f5;
@BLK50: rgb(128, 128, 128);

@blue-slate: #586977; //depercated
@green-slate: #798589; //depercated
//@grey: #a0abb3; //depercated

// TC update @seafoam: #dee5e1;
@seafoam: #f2f3f5;
// TC update @salt: #f2f5f4;
@salt: #f8f9fb;
// TC update @shady-beige: #f2f2ef;
@shady-beige: #f8f9fb;
// TC update @beige: #f8f8f5;
@beige: #f8f9fb;
@mercury: #e8e8e8;

//Accents
// TC update @gold: #f7c629;
@gold: #ed0b6f;
// TC update @gold-medium: #fbe294;
@gold-medium: #fbcee2;
// TC update @gold-medium-light: #fbe294b3;
@gold-medium-light: #fbcee2;
@gold-light: #fcebb4;
// TC update @gold50: #fef9ea;
@gold50: #fee7f1;
// TC update @accent-green: #0f8564;
@accent-green: #13b34e;
@pink-gradient-start: #c9806c;
@pink-gradient-end: #0e0b0b;
@pink-gradient: linear-gradient(-90deg, #c9806c, #e09d8a);
@beige-gradient-start: #ffffff;
// TC update @beige-gradient-end: #f8f8f5;
@beige-gradient-end: #f8f9fb;
// TC update @beige-gradient: linear-gradient(90deg, #ffffff, #f8f8f5);
@beige-gradient: linear-gradient(90deg, #ffffff, #f8f9fb);
// TC update @gray-base-gradient: linear-gradient(-180deg, #ffffff 0%, #f5f7f8 45%);
@gray-base-gradient: linear-gradient(-180deg, #ffffff 0%, #f8f9fb 45%);
@gray-base-gradient-full-up: linear-gradient(360deg, #ffffff 0%, #f8f8f5 100%);
@gray-base-gradient-full-down: linear-gradient(
  180deg,
  #ffffff 0%,
  #f8f8f5 100%
);
@gray-base-gradient-dark: linear-gradient(0deg, #2e2e2d, #727270);

@base-gradient-light: linear-gradient(0deg, #f2f8fe 75%, #ffffff);

@gray-base-gradient-light: linear-gradient(
  -180deg,
  #ffffff 0%,
  #f8f8f5 75%,
  #f8f8f5 75%
);
@gray-full-gradient-light: linear-gradient(-180deg, #ffffff 50%, #f8f8f5 100%);

@overlay: rgba(34, 61, 80, 0.6);
@indigo-light: rgba(34, 61, 80, 0.05);

// specialty icon colors
@pink: #d0a38f; //depercated
@orange: #f38772; //depercated

//Support
@red: #c62727;
@light-red: #f9e9e9;
@tall-poppy: #b22727;
@mexican-red: #9e2727;
@green: #5ea144;
@light-green: #d0f5de;
@light-yellow: #fff0cd;
@yellow: #ffd000; //depercated

//datePicker color
@blue-twilight: #f0fbff;
@blue-picton: #59b3f0;

//temporary colors
@mammogram-pink: #f7cdca;
@claret-red: #c6515f;

// color added for video visit
@magnolia: #f5e5ff;

@media-xs: 0px;
@media-sm-min: 320px;
@media-sm: 576px;
@media-sm-max: 767px;
@media-md: 768px;
@media-md-max: 991px;
@media-lg: 992px;
@media-xlg: 1024px;
@media-xlg-max: 1199px;
@media-xl: 1200px;
@media-xxl: 1600px;

.ant-card-body {
  padding: 0;
}

.allResults {
  a {
    color: rgba(0, 0, 0, 0.65) !important;
  }
  &:hover {
    cursor: pointer !important;
  }
}
